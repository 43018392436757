import { useMutation } from '@apollo/client';
import { Card, Form, Input } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { CHANGE_EMAIL } from '../../auth/graphql/Mutations';
import CardActionBtn from './CardActionBtn';

const { required, email, emailWithPlus } = formValidatorRules;

const emailVerifier =
  process.env.REACT_APP_ENV === 'development' ? emailWithPlus : email;

const ChangeEmail = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const history = useHistory();
  const [newEmailForm] = Form.useForm();
  const [payloadEmail, setPayloadEmail] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState({
    changeEmail: true,
    changePassword: true,
  });
  const [changeEmail, { loading: changeEmailLoading }] = useMutation(
    CHANGE_EMAIL,
    {
      onCompleted() {
        history?.push({
          pathname: `${ROUTES?.VERIFY_NEW_EMAIL}/${user?.id}`,
          state: { email: payloadEmail },
        });
      },
      onError() {},
    },
  );
  const handleChangeMailForm = (formValue) => {
    setPayloadEmail(formValue?.newEmail);
    changeEmail({
      variables: {
        data: {
          newEmail: formValue?.newEmail,
          userId: user?.id,
        },
      },
    });
  };
  const onValueChangeNewEmail = (changedValues, allValues) => {
    const isEmpty = !allValues.newEmail || allValues.newEmail.trim() === '';
    setIsSaveDisabled((prev) => ({ ...prev, changeEmail: isEmpty }));
  };
  const handleFormSubmit = () => {
    newEmailForm.submit();
  };

  return (
    <>
      <Card
        className="full-height"
        title={
          <CardActionBtn
            title="Change Email"
            saveBtnProps={{
              saveClickHandler: handleFormSubmit,
              saveDisableHandler: isSaveDisabled.changeEmail,
            }}
          />
        }
      >
        <Form
          form={newEmailForm}
          size="middle"
          layout="vertical"
          onFinish={handleChangeMailForm}
          onValuesChange={onValueChangeNewEmail}
        >
          <Form.Item
            name="newEmail"
            label="New Email"
            rules={[
              {
                ...required,
                message: 'Please enter new email',
              },
              emailVerifier,
            ]}
          >
            <Input
              disabled={changeEmailLoading}
              placeholder="Enter new email"
              allowClear
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ChangeEmail;
