import { useMutation } from '@apollo/client';
import { Card, Form, Input, message } from 'antd';
import React, { useRef, useState } from 'react';
import { REGEX } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { CHANGE_PASSWORD } from '../../auth/graphql/Mutations';
import CardActionBtn from './CardActionBtn';

const { Password } = Input;
const { required } = formValidatorRules;
const ChangePassword = () => {
  const [form] = Form?.useForm();
  const inputRef = useRef();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [changePassword, { loading: passwordLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted() {
        form?.resetFields();
        setIsSaveDisabled(true);
      },
      onError() {},
    },
  );
  const onValueChangePassword = (changedValues, allValues) => {
    const isEmpty =
      !allValues.confirmPassword ||
      !allValues.newPassword ||
      allValues.confirmPassword.trim() === '' ||
      allValues.newPassword.trim() === '';
    setIsSaveDisabled(isEmpty);
  };
  const handleChangePassword = (password) => {
    if (password?.newPassword === password?.confirmPassword) {
      changePassword({
        variables: {
          data: {
            newPassword: password?.newPassword,
          },
        },
      });
    } else {
      message.error('New and Confirm password does not match');
      inputRef?.current?.focus({
        cursor: 'all',
      });
    }
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Card
        className="full-height"
        title={
          <CardActionBtn
            title="Change Password"
            saveBtnProps={{
              saveClickHandler: handleFormSubmit,
              saveDisableHandler: isSaveDisabled,
              saveBtnLoader: passwordLoading,
            }}
          />
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleChangePassword}
          onValuesChange={onValueChangePassword}
          disabled={passwordLoading}
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                ...required,
                message: 'Please enter new password',
              },
              {
                pattern: REGEX?.PASSWORD,
                validator: (_, newPassword) => {
                  if (newPassword && newPassword?.length < 8) {
                    return Promise?.reject(new Error('Password is too short'));
                  }
                  return Promise?.resolve();
                },
              },
            ]}
          >
            <Password
              disabled={passwordLoading}
              placeholder="Enter new password"
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              {
                ...required,
                message: 'Please re-enter password',
              },
            ]}
          >
            <Password
              ref={inputRef}
              disabled={passwordLoading}
              placeholder="Re-enter password"
              allowClear
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ChangePassword;
