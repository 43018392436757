import { Button, Col, Row } from 'antd';
import React from 'react';

const CardActionBtn = ({ title = '', saveBtnProps }) => {
  const { saveBtnLoader, saveClickHandler, saveDisableHandler } =
    saveBtnProps || {};
  return (
    <div className="d-flex align-center justify-between">
      <h3>{title}</h3>
      <Row gutter={[16, 16]} className="d-flex align-center">
        <Col span={24}>
          <Button
            type="primary"
            loading={saveBtnLoader}
            htmlType="submit"
            size="middle"
            className="all-btn"
            onClick={saveClickHandler}
            disabled={saveDisableHandler}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CardActionBtn;
