import { Badge, Card } from 'antd';
import React, { useContext, useMemo } from 'react';
import { AppContext } from '../AppContext';
import { PLANS } from '../common/constants';
import { getPlanColor } from '../common/utils';

const CurrentPlanCard = () => {
  const {
    state: { currentUser },
    getCurrentUser,
  } = useContext(AppContext);
  const user = getCurrentUser();
  const returnPlanColor = useMemo(() => getPlanColor(currentUser), [
    currentUser,
  ]);
  return (
    <>
      <Card className="full-height" hoverable>
        <h1 className="m-0 text-16 mb-8">Active plan information</h1>
        <Badge
          size="default"
          color={returnPlanColor}
          count={PLANS?.[user?.subscriptionPlan?.key]?.label}
        />
      </Card>
    </>
  );
};

export default CurrentPlanCard;
