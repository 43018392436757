import { FacebookFilled, TwitterOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CommentIcon, LikeIcon, ShareIcon } from '../../../assets/svg';
import { LAYOUT_OPTIONS } from '../../../common/constants';
import {
  convertToCompactFormat,
  shareOnFacebook,
  shareOnTwitter,
} from '../../../common/utils';
import i18n from '../../../i18n';

const CustomPopover = ({ postUrl = '', t, postCustomStyle, open = false }) => {
  const { fontColor, fontFamily } = postCustomStyle || {};
  const fontStyles = {
    fontFamily,
    color: open ? 'black' : fontColor,
  };
  const data = [
    {
      icon: <FacebookFilled className="font-2xl" />,
      label: t('Share on Facebook'),
      shareOn: () => shareOnFacebook(postUrl),
    },
    {
      icon: <TwitterOutlined className="font-2xl" />,
      label: t('Share on Twitter'),
      shareOn: () => shareOnTwitter('', postUrl),
    },
  ];
  return (
    <div className="background-white custom-popover">
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            className="d-flex align-center col-gap-8 justify-start"
            onClick={() => item?.shareOn()}
          >
            <span style={fontStyles}>{item?.icon}</span>
            <span style={fontStyles}>{item?.label}</span>
          </List.Item>
        )}
      />
    </div>
  );
};

const PostFooter = ({
  postData,
  controlPostElement,
  inModal = false,
  col,
  context,
  open = false,
}) => {
  const {
    state: {
      postModule: { postCustomStyle },
      previewStyle,
    },
  } = useContext(context);

  const isMasonry = previewStyle === LAYOUT_OPTIONS?.MASONRY?.value;

  const { t } = i18n;
  const [toggle, setToggle] = useState(false);
  const { likeCount, commentsCount, caption, permalink } = postData;
  const isLikeShareCommentVisible =
    controlPostElement('likeCount') ||
    controlPostElement('likesCount') ||
    controlPostElement('commentCount') ||
    controlPostElement('comments') ||
    controlPostElement('share') ||
    controlPostElement('text');
  const footerRef = useRef();
  const popoverRef = useRef();
  const { padding, margin, fontColor, fontFamily } = postCustomStyle || {};

  const fontStyles = {
    color: open ? 'black' : fontColor,
    fontFamily,
  };

  const iconFill = open ? 'black' : fontColor;

  const handleSharePopover = () => {
    setToggle(!toggle);
  };

  const handleClickOutside = (event) => {
    if (popoverRef?.current && !popoverRef?.current?.contains(event?.target)) {
      setToggle(false);
    }
  };
  const columnIsGreaterThan8 = useMemo(() => col >= 7, [col]);

  useEffect(() => {
    let currentTarget;
    // eslint-disable-next-line no-undef
    const iframe = document?.getElementById('previewIframe')?.contentWindow
      ?.document;
    if (iframe) {
      currentTarget = iframe;
    }
    if (!iframe) {
      // eslint-disable-next-line no-undef
      currentTarget = document;
    }
    // eslint-disable-next-line no-undef
    currentTarget?.addEventListener('mousedown', handleClickOutside);
    return () => {
      // eslint-disable-next-line no-undef
      currentTarget?.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const showLike = useMemo(
    () =>
      (controlPostElement('likeCount') || controlPostElement('likesCount')) &&
      'likeCount' in postData &&
      likeCount !== null,
    [postData, controlPostElement, inModal],
  );
  const showComment = useMemo(
    () =>
      (controlPostElement('commentCount') || controlPostElement('comments')) &&
      'commentsCount' in postData &&
      commentsCount !== null,
    [postData, controlPostElement, inModal],
  );

  return (
    <div
      style={{
        padding,
        margin,
      }}
      ref={footerRef}
      className={`post-footer ${
        isLikeShareCommentVisible && !inModal
          ? 'pl-6  pr-6 pt-12 pb-12'
          : 'p-12'
      } ${
        controlPostElement('text') &&
        !inModal &&
        !columnIsGreaterThan8 &&
        !isMasonry
          ? 'post-footer-min-height'
          : ''
      }`}
    >
      <div className="comment-like-share-section d-flex justify-between align-center">
        <div
          className={`comment-like-container d-flex justify-between ${
            columnIsGreaterThan8 ? 'col-gap-6' : 'col-gap-12'
          } `}
        >
          {showLike && (
            <div
              className={`d-flex align-center ${
                columnIsGreaterThan8 || !inModal ? 'col-gap-4' : 'col-gap-8'
              }`}
            >
              <LikeIcon
                fill={iconFill}
                className={`icon-in-footer ${
                  columnIsGreaterThan8 ? 'h-20' : ''
                }`}
                viewBox="0 0 24 24"
              />

              <span className="font-s" style={fontStyles}>
                {convertToCompactFormat(likeCount)}
              </span>
            </div>
          )}

          {showComment && (
            <div
              className={`d-flex align-center ${
                columnIsGreaterThan8 ? 'col-gap-4' : 'col-gap-8'
              }`}
            >
              <CommentIcon
                fill={iconFill}
                title={convertToCompactFormat(commentsCount)}
                className={`icon-in-footer ${
                  columnIsGreaterThan8 && !inModal ? 'h-20' : ''
                }`}
                viewBox="0 0 24 24"
              />
              <span style={fontStyles}>
                {convertToCompactFormat(commentsCount)}
              </span>
            </div>
          )}
        </div>

        {controlPostElement('share') && (
          <div className="share-container">
            {toggle && (
              <div ref={popoverRef}>
                <CustomPopover
                  postUrl={permalink}
                  t={t}
                  postCustomStyle={postCustomStyle}
                  open={open}
                />
              </div>
            )}
            <div
              className="pointer d-flex align-center col-gap-6"
              onClick={handleSharePopover}
            >
              <ShareIcon
                fill={iconFill}
                className={`text-22 ${
                  columnIsGreaterThan8 && !inModal ? 'h-20' : ''
                }`}
                viewBox="0 0 24 24"
              />
              {(!columnIsGreaterThan8 || inModal) && (
                <span style={fontStyles}>{t('Share')}</span>
              )}
            </div>
          </div>
        )}
      </div>

      {controlPostElement('text') &&
        'caption' in postData &&
        postData?.caption !== null &&
        (!columnIsGreaterThan8 || inModal) && (
          <div
            style={fontStyles}
            className={` post-caption ${!inModal ? 'show-less' : ''} mt-8`}
          >
            {caption}
          </div>
        )}
    </div>
  );
};

export default PostFooter;
