import { Button, Carousel, Image, Modal } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../AppContext';
import { GA_EVENT_NAMES, ONBOARD_MODAL } from '../common/constants';
import { GaEvent } from '../common/utils';

const OnboardTutorial = () => {
  const {
    setOnboardModal,
    state: { onboardModal },
  } = useContext(AppContext);
  const carouselRef = useRef(null);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const resetCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.goTo(0, false); // goTo method is used to reset to the first slide
    }
  };
  const handleDone = () => {
    GaEvent(
      GA_EVENT_NAMES.ONBOARDING_END,
    ); /* GE event to track onboarding end */
    resetCarousel();
    setOnboardModal(false);
    setIsLastSlide(false);
  };
  const nextSlide = () => {
    if (!isLastSlide && carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const onChange = (currentSlideAlt) => {
    if (currentSlideAlt === 1) {
      GaEvent(
        GA_EVENT_NAMES.ONBOARDING_START,
      ); /* GA event to track onboarding start */
    }
    if (currentSlideAlt === ONBOARD_MODAL?.length - 1) {
      setIsLastSlide(true);
    }
  };
  return (
    <>
      <Modal
        className="overflow-auto"
        open={onboardModal}
        onCancel={() => {
          setOnboardModal(false);
        }}
        width={1000}
        closable={false}
        maskClosable={false}
        footer={
          <>
            <Button
              type="link"
              onClick={() => {
                GaEvent(GA_EVENT_NAMES.ONBOARDING_SKIP); // GA event to track onboarding skip
                setOnboardModal(false);
                resetCarousel();
              }}
            >
              <u>Skip</u>
            </Button>
            <Button
              type="primary"
              className="btn-rounded"
              onClick={isLastSlide ? handleDone : nextSlide}
            >
              {isLastSlide ? 'Done' : 'Next'}
            </Button>
          </>
        }
      >
        <div>
          <Carousel
            afterChange={onChange}
            infinite={false}
            ref={carouselRef}
            dots={false}
          >
            {ONBOARD_MODAL?.map((item) => (
              <div key={item?.key}>
                <center>
                  <div className="onboard-modal">
                    <Image
                      preview={false}
                      width="100%"
                      src={item?.image}
                      alt="image-placeholder"
                    />
                  </div>
                </center>
                <div className="onboard-description">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

export default OnboardTutorial;
