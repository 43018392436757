import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import UserProfile from '../../app/components/header/UserProfile';
import { USER } from '../../common/constants';
import CommonLogo from '../../components/CommonLogo';
import OnboardTutorial from '../../components/OnboardTutorial';
import Portal from '../../components/Portal';
import ChangeEmail from './components/ChangeEmail';
import ChangePassword from './components/ChangePassword';
import ProfileCard from './components/ProfileCard';

function Profile() {
  const history = useHistory();
  // eslint-disable-next-line no-undef
  const getUser = localStorage?.getItem(USER);
  const parsedUser = JSON?.parse(getUser);
  const checkProvider = parsedUser?.authProvider;

  return (
    <>
      <OnboardTutorial />
      <div className="mt-8 mb-8">
        <Button
          icon={<LeftOutlined />}
          type="link"
          className="all-btn leading-1"
          onClick={() => history?.goBack()}
        >
          Go back
        </Button>
      </div>
      <Portal portalId="header-title">
        <CommonLogo src="/logo-light.png" classes="full-logo" />
      </Portal>
      <Portal portalId="profile">
        <UserProfile />
      </Portal>
      <div className="d-flex align-center justify-center mt-8 mb-8">
        <div
          className={
            checkProvider !== 'CUSTOM'
              ? 'custom-auth-profile-container'
              : 'profile-container'
          }
        >
          <ProfileCard />
          {checkProvider === 'CUSTOM' && (
            <>
              <ChangePassword />
              <ChangeEmail />
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Profile;
