import Gleap from 'gleap';
import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  COL_ROW_OPTIONS,
  LAYOUT_OPTIONS,
  POST_ACTION,
  POST_STYLE,
  REFRESH_TOKEN,
  TOKEN,
  USER,
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: {},
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  showPrompt: false,
  disablePublishButton: false,
  previewStyle: LAYOUT_OPTIONS?.SLIDER?.value,
  columnMode: COL_ROW_OPTIONS?.AUTO?.value,
  previewLayoutObject: {
    col: 4,
    row: 1,
    gap: 0,
    width: '',
  },
  showHeaders: false,
  headerController: [],
  sliderSettings: {
    animationSpeed: 1,
    arrowControl: true,
    autoPlay: 0,
    dragControl: true,
  },
  feedTitle: '',
  postModule: {
    style: POST_STYLE?.HOVER?.value,
    postElement: [],
    clickAction: POST_ACTION?.INSTAGRAM?.value,
    popUPSettings: [],
    callToAction: [],
    postCustomStyle: {
      fontColor: '#000000',
      fontFamily: 'inherit',
      margin: 4,
      padding: 4,
      isDarkMode: false,
      borderStyle: 'solid',
      borderColor: '#EAEAEA',
      borderWidth: 1,
    },
  },
  hasMoreMediaData: true,
  openFeatureAccessModal: {
    status: false,
    title: '',
    info: '',
  },
  mailSubject: '',
  onboardModal: false,
  redirect: false,
  postData: [],
  postCount: 0,
  isAccountConnected: false,
  postModerationPreview: false,
  showSidebar: true,
  openPreviewModal: false,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'SET_SHOW_PROMPT':
      return { ...state, showPrompt: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      Gleap?.clearIdentity();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action?.data);
      return {
        ...state,
        refreshToken: action?.data,
      };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_SHOW_GRID':
      return { ...state, previewStyle: action?.data };
    case 'SET_PREVIEW_LAYOUT':
      return { ...state, previewLayoutObject: action?.data };
    case 'SET_SHOW_HEADER':
      return { ...state, showHeaders: action?.data };
    case 'SET_HEADER_CONTROLLER':
      return { ...state, headerController: action?.data };
    case 'SET_ACCOUNT_CONNECTION':
      return { ...state, isAccountConnected: action?.data };
    case 'SET_COLUMN_MODE':
      return { ...state, columnMode: action?.data };
    case 'SET_SLIDER_SETTINGS':
      return { ...state, sliderSettings: action?.data };
    case 'SET_FEED_TITLE':
      return { ...state, feedTitle: action?.data };
    case 'SET_POST_MODULE':
      return { ...state, postModule: action?.data };
    case 'DISABLE_PUBLISH_ACTION':
      return { ...state, disablePublishButton: action?.data };
    case 'CHECK_HAS_MORE_DATA':
      return { ...state, hasMoreMediaData: action?.data };
    case 'TOGGLE_FEATURE_ACCESS_MODAL':
      return { ...state, openFeatureAccessModal: action?.data };
    case 'SET_MAIL_SUBJECT':
      return { ...state, mailSubject: action?.data };
    case 'SET_ONBOARD_MODAL':
      return { ...state, onboardModal: action?.data };
    case 'SET_POST_MODERATION_PREVIEW':
      return { ...state, postModerationPreview: action?.data };
    case 'SET_SHOW_SIDEBAR':
      return { ...state, showSidebar: action?.data };
    case 'SET_CUSTOM_STYLE':
      return { ...state, postCustomStyle: action?.data };
    case 'SET_REDIRECT':
      return { ...state, redirect: action?.data };
    case 'SET_POST_DATA':
      return { ...state, postData: action?.data };
    case 'SET_POST_COUNT':
      return { ...state, postCount: action?.data };
    case 'SET_OPEN_PREVIEW_MODAL':
      return { ...state, openPreviewModal: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.roles?.[0] || '';
  };

  const setCurrentUser = (data) => {
    dispatch({ type: 'SET_CURRENT_USER', data });
  };

  const disablePublishAction = (data) => {
    dispatch({ type: 'DISABLE_PUBLISH_ACTION', data });
  };

  const togglePreviewLayout = (data) => {
    dispatch({ type: 'SET_SHOW_GRID', data });
  };

  const handlePreviewLayout = (data) => {
    dispatch({ type: 'SET_PREVIEW_LAYOUT', data });
  };
  const handleAccountConnectionStatus = (data) => {
    dispatch({ type: 'SET_ACCOUNT_CONNECTION', data });
  };

  const handleHeaderController = (data) => {
    dispatch({ type: 'SET_HEADER_CONTROLLER', data });
  };
  const handleShowHeader = (data) => {
    dispatch({ type: 'SET_SHOW_HEADER', data });
  };
  const handleColumnMode = (data) => {
    dispatch({ type: 'SET_COLUMN_MODE', data });
  };
  const handleSliderSettings = (data) => {
    dispatch({ type: 'SET_SLIDER_SETTINGS', data });
  };
  const handleFeedTitle = (data) => {
    dispatch({ type: 'SET_FEED_TITLE', data });
  };
  const handlePostModule = (data) => {
    dispatch({ type: 'SET_POST_MODULE', data });
  };
  const handlePostData = (data) => {
    dispatch({ type: 'SET_POST_DATA', data });
  };
  const handlePostCount = (data) => {
    dispatch({ type: 'SET_POST_COUNT', data });
  };
  const checkHasMoreData = (data) => {
    dispatch({ type: 'CHECK_HAS_MORE_DATA', data });
  };

  const toggleFeatureAccessModal = (data) => {
    dispatch({ type: 'TOGGLE_FEATURE_ACCESS_MODAL', data });
  };

  const setMailSubject = (data) => {
    dispatch({ type: 'SET_MAIL_SUBJECT', data });
  };

  const setOnboardModal = (data) => {
    dispatch({ type: 'SET_ONBOARD_MODAL', data });
  };

  const setIsPostModerationPreview = (data) => {
    dispatch({ type: 'SET_POST_MODERATION_PREVIEW', data });
  };

  const setShowSidebar = (data) => {
    dispatch({ type: 'SET_SHOW_SIDEBAR', data });
  };

  const setOpenPreviewModal = (data) => {
    dispatch({ type: 'SET_OPEN_PREVIEW_MODAL', data });
  };

  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, userData, refreshToken) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refresh });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      Gleap?.identify(userData?.id, {
        name: user?.fullName,
        email: user?.email,
        createdAt: new Date(),
      });
      if (user?.roles) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user?.roles });
      }
    }
  };

  const initializeSocialAuth = (socialAuthLink) => {
    const token = socialAuthLink || getToken();
    if (token) {
      api.defaults.headers.common.Authorization = token;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    initializeSocialAuth,
    getCurrentUserRole,
    getCurrentUser,
    togglePreviewLayout,
    handlePreviewLayout,
    handleHeaderController,
    handleShowHeader,
    handleColumnMode,
    handleSliderSettings,
    handleFeedTitle,
    handlePostModule,
    disablePublishAction,
    checkHasMoreData,
    toggleFeatureAccessModal,
    setCurrentUser,
    setMailSubject,
    setOnboardModal,
    handlePostData,
    handlePostCount,
    handleAccountConnectionStatus,
    setIsPostModerationPreview,
    setShowSidebar,
    setOpenPreviewModal,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
