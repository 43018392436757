import { Card, Col, ColorPicker, InputNumber, Row, Select, Switch } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { FONT_FAMILIES_LIST } from '../../common/constants';
import CustomColorPicker from '../../components/CustomColorPicker';
import InfoTooltip from '../../components/InfoTooltip';

const PostCustomCSS = () => {
  const {
    state: { postModule, openPreviewModal },
    handlePostModule,
  } = useContext(AppContext);

  const { postCustomStyle } = postModule;
  const { margin, padding, fontFamily, borderColor, borderWidth, isDarkMode } =
    postCustomStyle || {};

  const fontFamilyOptions = FONT_FAMILIES_LIST.map((font) => ({
    value: font,
    label: <div style={{ fontFamily: font }}>{font}</div>,
  }));

  const fontOptions = [
    { label: 'No selection', value: 'inherit' },
    ...fontFamilyOptions,
  ];

  const handleFontFamilyChange = (selectedValue) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        fontFamily: selectedValue,
      },
    });
  };

  const handlePadding = (paddingValue) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        padding: paddingValue,
      },
    });
  };

  const handleMargin = (marginValue) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        margin: marginValue,
      },
    });
  };

  const handleModeChange = (darkModeValue) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        isDarkMode: darkModeValue,
      },
    });
  };

  const handleBorderChange = (borderWidthValue) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        borderWidth: borderWidthValue,
      },
    });
  };

  const handleBorderColor = (value, hex) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: {
        ...postCustomStyle,
        borderColor: hex,
      },
    });
  };
  return (
    <div>
      <Card title="Font settings" size="small">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Row className="align-center d-flex">
              <Col span={8}>Family</Col>
              <Col span={16}>
                <Select
                  value={fontFamily}
                  onChange={handleFontFamilyChange}
                  className="full-width"
                  placeholder="Select font family"
                  options={fontOptions}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="align-center d-flex">
              <Col span={8}>Color</Col>
              <Col span={16}>
                <CustomColorPicker />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card title="Border settings" size="small" className="mt-16">
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Row className="align-center d-flex">
              <Col span={8}>
                Width <InfoTooltip />
              </Col>
              <Col span={16}>
                <InputNumber
                  min={0}
                  max={16}
                  value={borderWidth}
                  onChange={handleBorderChange}
                  placeholder="border"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="align-center d-flex">
              <Col span={8}>Color</Col>
              <Col span={16}>
                <ColorPicker
                  defaultValue={borderColor}
                  onChange={handleBorderColor}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card title="Other settings" size="small" className="mt-16">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Row gutter={[8, 8]} className="align-center d-flex">
              <Col span={8}>
                Padding
                <InfoTooltip />
              </Col>
              <Col span={16}>
                <InputNumber
                  value={padding}
                  min={0}
                  max={16}
                  onChange={handlePadding}
                  placeholder="Padding"
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[8, 8]} className="align-center d-flex">
              <Col span={8}>
                Margin
                <InfoTooltip />
              </Col>
              <Col span={16}>
                <InputNumber
                  value={margin}
                  min={0}
                  max={16}
                  onChange={handleMargin}
                  placeholder="Margin"
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[8, 8]} className="align-center d-flex">
              <Col span={8}>Dark Mode</Col>
              <Col span={16}>
                <Switch
                  value={isDarkMode}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  onChange={handleModeChange}
                  disabled={openPreviewModal}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PostCustomCSS;
